import React from "react";
import { connect } from "react-redux"; // Import connect
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from "react-bootstrap";
import ShoppingCartTable from "../../components/eshop/ShoppingCartTable";
import { deleteFromCart, updateQuantity } from "../../actions/actions";

import "./ShoppingCart.css";

function ShoppingCart(props) { 
    const { cartItems, deleteFromCart, orderUUID } = props;
    const navigate = useNavigate();

    const proceedToCheckout = () => {
        // Check if cart is not empty
        if (cartItems.length > 0) {
            // Check if UUID is not null or undefined
            if (orderUUID) {
                // Redirect to checkout
                navigate(`/eshop/checkout/${orderUUID}`);
            } 
            else {
                // UUID is missing, show alert
                alert('UUID is missing. Please add items before proceeding to checkout.');
            }
        } 
        else {
            // Cart is empty, show alert
            alert('Your cart is empty. Please add items before proceeding to checkout.');
        }
    };

    return (
        <Container className="shopping-cart-body">
            <Row>
                <Col className="shopping-cart-title">
                    <h1>My Shopping Cart</h1>
                </Col>
            </Row>

            {/* Conditional rendering based on cartItems length */}
            <Row className="cart-table-row">
                {cartItems.length === 0 ? (
                    <Col>
                        <div className="empty-cart-message text-center p-4">
                            <p>Your shopping cart is empty.</p>
                            <p>Please browse the store and add items to your cart.</p>
                        </div>
                    </Col>
                ) : (
                    <ShoppingCartTable items={cartItems} deleteFromCart={deleteFromCart} />
                )}
            </Row>

            {/* Proceed to Checkout Button, disabled when cart is empty */}
            <Button 
                className="checkout-btn" 
                size="lg" 
                onClick={proceedToCheckout} 
                disabled={cartItems.length === 0} // Disable if cart is empty
            >
                Proceed to Checkout
            </Button>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    cartItems: state.items.cartItems,
    orderUUID:  state.items.orderUUID
});

const mapDispatchToProps = {
    deleteFromCart,
    updateQuantity
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart); // Connect to Redux store
