import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import CloseButton from 'react-bootstrap/CloseButton';
import Image from 'react-bootstrap/Image';
import { Row, Col, Container } from 'react-bootstrap';
import getImageUrl from '../../utils/eshop/getImageUrlUtil';

import QuantityControl from './QuantityControl';

import { deleteFromCart, updateQuantity } from '../../actions/actions';

import './ShoppingCartModal.css';

function ShoppingCartModal(props) {
    const { cartItems, onHide, deleteFromCart, updateQuantity } = props;
    const [reloadModal, setReloadModal] = useState(false);
    const [itemRemoved, setItemRemoved] = useState(false); // State to track item removal
    const navigate = useNavigate();

    const gotoCart = () => {
        onHide();
        navigate("/eshop/cart");
    };

    const handleQuantityChangeItem = (item, newQuantity) => {
        updateQuantity(item.product.id, newQuantity);
    }

    const handleRemoveItem = (itemId) => {
        deleteFromCart(itemId);
        setItemRemoved(true); // Set itemRemoved to true when an item is removed
    }

    useEffect(() => {
        if (itemRemoved) {
            setReloadModal(prevState => !prevState); // Trigger modal reload only when an item is removed
            setItemRemoved(false); // Reset itemRemoved state after modal reload
        }
    }, [itemRemoved]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            key={reloadModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Your Shopping Cart
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Items</h4>
                
                {cartItems.length === 0 ? (
                    <div className="empty-cart-message text-center p-4">
                        <p>Your shopping cart is empty.</p>
                        <p>Please browse the store and add items to your cart.</p>
                    </div>
                ) : (
                    <ListGroup>
                        {cartItems.map((item, index) => (
                            <Row key={index} className='cart-item-row'>
                                <Col>
                                    <Image
                                        className='cart-modal-thumbnail'
                                        thumbnail
                                        src={getImageUrl(item.product.images[0])}
                                    />
                                </Col>
                                <Col>
                                    <ListGroup.Item className='cart-item'>
                                        {item.product.name}
                                    </ListGroup.Item>
                                </Col>
                                <Col>
                                    <QuantityControl
                                        onQuantityChange={(newQuantity) => handleQuantityChangeItem(item, newQuantity)}
                                        defaultQuantity={item.quantity}
                                    />
                                </Col>
                                <Col xs="auto">
                                    <CloseButton
                                        className='remove-cart-item'
                                        onClick={() => handleRemoveItem(item.product.id)}
                                    />
                                </Col>
                            </Row>
                        ))}
                    </ListGroup>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Continue Shopping</Button>
                <Button onClick={gotoCart} disabled={cartItems.length === 0}>
                    Proceed to Cart
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    cartItems: state.items.cartItems
});

const mapDispatchToProps = {
    deleteFromCart,
    updateQuantity
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartModal);
