import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'; // Connect to Redux
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Searchbar from './Searchbar';
import ShoppingCartModal from './ShoppingCartModal';
import './Header.css';
import axios from 'axios';
import { getCategoryDisplayName } from '../../utils/eshop/categoryNameUtil';

function Header({ cartItems }) {
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);

  // Calculate total items count and total price in cart
  const itemCount = cartItems.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = cartItems.reduce((total, item) => total + item.product.price * item.quantity, 0).toFixed(2);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories/get/all`);
        let categoryNames = response.data.map(category => category.name); // Extract only names
  
        // Find "All Items" and remove it from the list
        const allItemsIndex = categoryNames.indexOf("all-items");
        if (allItemsIndex !== -1) {
          categoryNames.splice(allItemsIndex, 1); // Remove "All Items"
        }
  
        // Sort the remaining categories alphabetically
        categoryNames.sort((a, b) => a.localeCompare(b));
  
        // Reinsert "All Items" at the beginning
        categoryNames.unshift("all-items");
  
        setCategories(categoryNames);
        console.log(categoryNames); // Log to verify the extracted names
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, []);
  
  

  const viewCart = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Navbar sticky="top" className="header-navbar">
        <Col>
          <Container className="header-cont">
            <Row>
              <Col>
                <a href="/">
                  <img src="/logos/logo SVK.png" className="header-logo" alt="Logo" />
                </a>
              </Col>

              <Col className="d-flex justify-content-center" xs={6}>
                <Searchbar />
              </Col>

              <Col className="d-flex justify-content-center align-items-center">
                <div className="shopping-cart-col" onClick={viewCart}>
                  <div className="shopping-cart-icon-wrapper">
                    <img
                      src="/icons/shopping-cart-01.svg"
                      className="shopping-cart-icon"
                      alt="Shopping Cart"
                    />
                    {itemCount > 0 && (
                      <span className="cart-badge">{itemCount}</span>
                    )}
                  </div>
                  <h5>View Cart</h5>
                  {/* <p className="cart-total-price">{totalPrice} €</p> */}
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="nav-container">
            <Nav>
              <Col md={2}></Col>
              <Col md={2}>
                <NavDropdown className="nav-dropdown" title="Categories">
                  {categories?.map((category, index) => (
                    <Link
                      to={`/eshop/category/${category}`}
                      key={index}
                      className="dropdown-item"
                    >
                      {getCategoryDisplayName(category)}
                    </Link>
                  ))}
                </NavDropdown>
              </Col>

              <div className="nav-links">
                <Link to="/eshop">Home</Link>
                <Link to="/eshop/category/robot-kits">Robot Kits</Link>
                <Link to="/eshop/category/robot-parts">Robot Parts</Link>
                <Link to="/eshop/category/educational-kits">Educational Kits</Link>
                <Link to="/contact">Contact Us</Link>
              </div>
            </Nav>
          </Container>
        </Col>
      </Navbar>

      <ShoppingCartModal show={showModal} onHide={handleClose} />
    </>
  );
}

// Map state to props
const mapStateToProps = (state) => ({
  cartItems: state.cartItems || [], // Access cartItems properly
});

export default connect(mapStateToProps)(Header);
