import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './Contact.css'; // Import the CSS file for styling

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div className="contact-page">
      <Container>
        <h1>Contact Us</h1>

        {/* Contact Information & Maps inside the Grey Container */}
        <Row>
          <Col className="contact-info">
            <h2>Contact Information</h2>
            <p><strong>Email:</strong> info@svkrobotics.com</p>
            <p><strong>Phone:</strong> +30 694 758 6765</p>

            <h2>Our Location</h2>

            <Row className="mt-3">

              {/* HQ Location */}
              <Col md={6}>
                <p>Greece</p>
                <p>Heraklion, Crete</p>
                <p>GIAMALAKI 25, 71202</p>
                <iframe 
                  title='Headquarters Location'
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3254.661999375163!2d25.12713700545938!3d35.33921399196639!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x149a5be3fb80294d%3A0xc0213651a2186700!2sSVK%20ROBOTICS!5e0!3m2!1sen!2sgr!4v1739177847327!5m2!1sen!2sgr" 
                  width="600" height="450" 
                  style={{border:0}} 
                  allowfullscreen="" 
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
