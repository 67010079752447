
// Function to get the human-readable text for stock status
const getStockStatusText = (status) => {
    switch (status) {
      case "available_immediately":
        return "Available Immediately";
      case "available_after_ordering":
        return "Available After Ordering";
      case "available_3_to_5_days":
        return "Available in 3-5 Days";
      case "available_7_to_10_days":
        return "Available in 7-10 Days";
      case "currently_unavailable":
        return "Currently Unavailable";
      default:
        return "Unknown Status";
    }
  };
  
  // Function to get the CSS class for stock status
  const getStockStatusClass = (status) => {
    switch (status) {
      case "available_immediately":
        return "in-stock";
      case "available_after_ordering":
        return "available-after-ordering";
      case "available_3_to_5_days":
        return "available-3-to-5";
      case "available_7_to_10_days":
        return "available-7-to-10";
      case "currently_unavailable":
        return "out-of-stock";
      default:
        return "unknown";
    }
  };
  
  // Export the functions to make them available elsewhere in the app
  export { getStockStatusText, getStockStatusClass };
  