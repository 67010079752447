import React, { useState, useEffect } from "react";
import ItemGrid from "../../components/eshop/ItemGrid";
import { useParams } from "react-router-dom";
import axios from 'axios';

import "./ShopGrid.css";
import { getCategoryDisplayName } from "../../utils/eshop/categoryNameUtil";

function ShopGrid() {
    const { categoryUrl } = useParams();
    const [categoryName, setCategoryName] = useState("");
    const [allCategories, setAllCategories] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories/get/all`);
                const categoryNames = response.data.map(category => category.name); // Extract only names
                setAllCategories(categoryNames);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };
    
        fetchData();
    }, []);
    
    useEffect(() => {
        if (allCategories.length > 0) {
            // Check if categoryUrl is in the extracted category names
            if (allCategories.includes(categoryUrl)) {
                setCategoryName(categoryUrl);
            } else {
                setCategoryName("all-items"); // Default category
            }
        }
    }, [allCategories, categoryUrl]);
    

    return (
        <>
            <h1 className="grid-title">{getCategoryDisplayName(categoryName)}</h1>
            <ItemGrid category={categoryName} />
        </>
    );
}

export default ShopGrid;
