import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Carousel } from 'react-bootstrap';
import axios from 'axios';
import { CustomPrevArrow, CustomNextArrow } from "./CustomArrows";
import getImageUrl from "../../utils/eshop/getImageUrlUtil";

import './MainCarousel.css';

function MainCarousel() {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchHighlightedItems = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/items/get/highlighted`);
        setItems(response.data.items);
      } catch (error) {
        console.error("Error fetching highlighted items:", error);
      }
    };

    fetchHighlightedItems();
  }, []);

  const handleImageClick = (itemSlug) => {
    navigate(`/eshop/item/${itemSlug}`);
  };

  return (
    <Container className="image-container">
      <Carousel
        prevIcon={<CustomPrevArrow />}
        nextIcon={<CustomNextArrow />}
      >
        {items?.map(item => (
          <Carousel.Item key={item.id}>
              <img
                  className="d-block"
                  src={getImageUrl(item.images)}
                  alt={item.name}
                  onClick={() => handleImageClick(item.slug)}
                  style={{
                      width: '100%',
                      height: '600px', // Set a specific height
                      objectFit: 'cover', // Ensures the image covers the area without distortion
                  }}
              />
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
}

export default MainCarousel;
