import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart } from "../../actions/actions";
import { Row, Col, Button, Container, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import ItemImageCarousel from "../../components/eshop/ItemImageCarousel";
import QuantityControl from "../../components/eshop/QuantityControl";
import Breadcrumbs from "../../components/eshop/Breadcrumbs";
import AddToCartModal from "../../components/eshop/AddToCartModal";
import MarkdownRenderer from "../../components/eshop/MarkdownRenderer";
import { getCategoryDisplayName } from "../../utils/eshop/categoryNameUtil";
import { getStockStatusClass, getStockStatusText } from "../../utils/eshop/stockUtils";

import "./Item.css";

function Item({ addToCart }) {
    const { slug } = useParams();  // Get slug from the URL
    const [itemData, setItemData] = useState(undefined);
    const [isInStock, setIsInStock] = useState(false);
    const [groupItems, setGroupItems] = useState([]);
    const [selectedGroupItem, setSelectedGroupItem] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [showAlert, setShowAlert] = useState(false);
    const [variations, setVariations] = useState([]); // State for variations
    const [selectedVariations, setSelectedVariations] = useState([]); // State for selected variations
    const navigate = useNavigate();

    useEffect(() => {
        const fetchItemData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/items/${slug}`);
                const item = response.data.item;
    
                setItemData(item);
                document.title = item.name;
    
                // Determine stock status based on stock quantity
                setIsInStock(item.stock > 0);
    
                // Set the selected item as the current one by default
                setSelectedGroupItem(item);
    
                // Fetch group items if group_id exists
                if (item.group_id && response.data.group) {
                    setGroupItems(response.data.group);
                }
    
                // Set variations if they exist in the response
                if (item.variations) {
                    setVariations(item.variations); // Set variations in state
    
                    // Automatically select the first exclusive variation if it exists
                    const exclusiveVariations = item.variations.filter(v => v.selection_type === "exclusive");
                    if (exclusiveVariations.length > 0) {
                        setSelectedVariations([exclusiveVariations[0]]); // Store the full variation object
                    }
                }
    
                // If the slug in the URL doesn't match the slug from the database, update the URL
                if (slug !== item.slug) {
                    navigate(`/eshop/item/${item.slug}`, { replace: true });
                }
            } catch (error) {
                console.error('Error fetching item data:', error);
            }
        };
    
        fetchItemData();
    }, [slug, navigate]);

    const handleQuantityChange = (newQuantity) => {
        setQuantity(newQuantity);
    };

    const handleAddToCart = () => {
        const orderUUID = uuidv4();
        addToCart(selectedGroupItem, quantity, orderUUID, selectedVariations); // Pass full variation objects
        setShowAlert(true); // Show alert when item is added to cart
    };

    const handleGroupItemChange = (selectedSlug) => {
        // Find the selected group item and set it as the selected one
        const selectedItem = groupItems.find(item => item.slug === selectedSlug);
        if (selectedItem) {
            setSelectedGroupItem(selectedItem);
            navigate(`/eshop/item/${selectedSlug}`, { replace: true });
        }
    };

    const handleExclusiveVariationChange = (variation) => {
        // For exclusive variations, only one can be selected at a time
        setSelectedVariations([variation]); // Replace the current selection with the new variation object
    };
    
    const handleOptionalVariationChange = (variation) => {
        // For optional variations, toggle the selection
        setSelectedVariations((prevSelected) => {
            const isAlreadySelected = prevSelected.some((v) => v.id === variation.id);
            if (isAlreadySelected) {
                return prevSelected.filter((v) => v.id !== variation.id); // Unselect variation
            } else {
                return [...prevSelected, variation]; // Select variation
            }
        });
    };

    if (itemData === undefined) {
        return <div>Loading...</div>;
    }

    // Separate variations into exclusive and optional
    const exclusiveVariations = variations.filter(v => v.selection_type === "exclusive");
    const optionalVariations = variations.filter(v => v.selection_type === "optional");

    return (
        <div className="item-page">
            {/* Added to cart ALERT container */}
            <Container>
                <Col>
                    <AddToCartModal showAlert={showAlert} setShowAlert={setShowAlert} />
                </Col>
            </Container>

            {/* Container for image carousel and add to cart buttons and other buttons */}
            <Row className="item-sale-cont">
                <Breadcrumbs type="item" data={itemData} />
                <Col>
                    <Container>
                        <ItemImageCarousel data={itemData} />
                    </Container>
                </Col>

                <Col>
                    <Container>
                        <h1>{itemData?.name}</h1>
                        <div className="product_code-hs-row">
                            {itemData?.product_code && <span className="product_code-text">Product Code: {itemData.product_code}</span>}
                            {itemData?.hs_code && <span className="hs-code-text">HS Code: {itemData.hs_code}</span>}
                        </div>
                        <hr />
                        <p className="item-categories">
                            Category: <a href={`/eshop/category/${itemData.category_name}`}>
                                {getCategoryDisplayName(itemData.category_name)}
                            </a>
                        </p>
                        <p>{itemData?.description_short}</p>

                        {/* Conditional Layout: Always Two Columns */}
                        <Row className="item-purchase-section">
                            {/* Variations Column (Empty if No Variations) */}
                            <Col md={5} className="variations-container">
                                {itemData?.group_id && (
                                    <Form>
                                        <h4>Select Product Type:</h4>
                                        <div className="group-buttons-container">
                                            {groupItems.map((groupItem) => (
                                                <Button
                                                    key={groupItem.id}
                                                    variant="light"
                                                    className={`group-item-button ${
                                                        selectedGroupItem?.slug === groupItem.slug ? "selected" : ""
                                                    }`}
                                                    onClick={() => handleGroupItemChange(groupItem.slug)}
                                                >
                                                    {groupItem.name}
                                                </Button>
                                            ))}
                                        </div>
                                    </Form>
                                )}

                                {/* Render exclusive variations first */}
                                {exclusiveVariations.length > 0 && (
                                    <h4>Select Variation</h4>
                                )}
                                {exclusiveVariations.map((variation) => (
                                    <Button
                                        key={variation.id}
                                        variant="light"
                                        className={`group-item-button ${
                                            selectedVariations.some((v) => v.id === variation.id) ? "selected" : ""
                                        }`}
                                        onClick={() => handleExclusiveVariationChange(variation)} // Pass the full variation object
                                    >
                                        {variation.name}
                                        {variation.variation_price !== 0 && (
                                            <span className="variation-price">+ €{variation.variation_price}</span>
                                        )}
                                    </Button>
                                ))}

                                {optionalVariations.length > 0 && (
                                    <h4>Optional Variations</h4>
                                )}
                                {optionalVariations.map((variation) => (
                                    <Button
                                        key={variation.id}
                                        variant="light"
                                        className={`group-item-button ${
                                            selectedVariations.some((v) => v.id === variation.id) ? "selected" : ""
                                        }`}
                                        onClick={() => handleOptionalVariationChange(variation)} // Pass the full variation object
                                    >
                                        {variation.name}
                                        {variation.variation_price !== 0 && (
                                            <span className="variation-price">+ €{variation.variation_price}</span>
                                        )}
                                    </Button>
                                ))}
                            </Col>

                            {/* Buying Information Column */}
                            <Col md={7} className="item-buy-container">
                                {/* Stock Status Frame */}
                                <div className={`stock-status ${getStockStatusClass(itemData?.stock_status)}`}>
                                    {getStockStatusText(itemData?.stock_status)}
                                </div>

                                {/* Price and Thumbnail Row */}
                                <Row className="price-thumbnail-row">
                                    <Col className="price-container">
                                        {itemData?.price && (
                                            <p className="item-price">
                                                <strong>
                                                    €{(
                                                        parseFloat(itemData?.price || 0) + // Ensure base price is a number (default to 0 if not available)
                                                        selectedVariations.reduce((total, variationId) => {
                                                            // Find the variation that is selected
                                                            const variation = [...exclusiveVariations, ...optionalVariations].find(
                                                                (variation) => variation.id === variationId
                                                            );
                                                            
                                                            // If the variation has a price and it's not 0, add it to the total
                                                            if (variation && !isNaN(parseFloat(variation.variation_price))) {
                                                                return total + parseFloat(variation.variation_price);
                                                            }
                                                            
                                                            // Otherwise, return the total unchanged
                                                            return total;
                                                        }, 0) // Initial total is 0
                                                    ).toFixed(2)} {/* Format the result to 2 decimal places */}
                                                </strong>
                                            </p>
                                        )}
                                    </Col>

                                    <Col className="thumbnail-container">
                                        <div className="thumbnail-placeholder">
                                            <img 
                                                src="/logos/logo SVK.png"
                                                alt="Thumbnail" 
                                                className="thumbnail-img"
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                {/* Quantity Selector and Add to Cart Button */}
                                <Row className="buy-row">
                                    {isInStock && (
                                        <Col>
                                            <QuantityControl onQuantityChange={handleQuantityChange} />
                                        </Col>
                                    )}
                                </Row>

                                {/* Add to Cart Button Row */}
                                <Row className="buy-row">
                                    <Col className="buy-button-col">
                                        <Button 
                                            size="lg" 
                                            className={`buy-button ${!isInStock ? 'out-of-stock' : ''}`} 
                                            onClick={handleAddToCart} 
                                            disabled={itemData.stock_status === "currently_unavailable"}
                                        >
                                            <svg width="45px" height="45px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g id="Interface / Shopping_Cart_01">
                                                    <path 
                                                        id="Vector" 
                                                        d="M17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17ZM17 17H9.29395C8.83288 17 8.60193 17 8.41211 16.918C8.24466 16.8456 8.09938 16.7291 7.99354 16.5805C7.8749 16.414 7.82719 16.1913 7.73274 15.7505L5.27148 4.26465C5.17484 3.81363 5.12587 3.58838 5.00586 3.41992C4.90002 3.27135 4.75477 3.15441 4.58732 3.08205C4.39746 3 4.16779 3 3.70653 3H3M6 6H18.8732C19.595 6 19.9555 6 20.1978 6.15036C20.41 6.28206 20.5653 6.48862 20.633 6.729C20.7104 7.00343 20.611 7.34996 20.411 8.04346L19.0264 12.8435C18.9068 13.2581 18.8469 13.465 18.7256 13.6189C18.6185 13.7547 18.4772 13.861 18.317 13.9263C18.1361 14 17.9211 14 17.4921 14H7.73047M8 21C6.89543 21 6 20.1046 6 19C6 17.8954 6.89543 17 8 17C9.10457 17 10 17.8954 10 19C10 20.1046 9.10457 21 8 21Z" 
                                                        stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" 
                                                    />
                                                </g>
                                            </svg>
                                            {itemData.stock_status === "currently_unavailable" ? "Currently Unavailable" : "Add to Cart"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            {/* Description Full Container */}
            {itemData?.description_full && (
                <Container className="desc-cont">
                    <MarkdownRenderer markdownText={itemData?.description_full} />
                </Container>
            )}

            {/* Specifications Container */}
            {itemData?.specifications && (
                <div className="specs-cont">
                    <Container>
                        <MarkdownRenderer markdownText={itemData?.specifications} />
                    </Container>
                </div>
            )}
        </div>
    );
}

export default connect(null, { addToCart })(Item);