import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Image from 'react-bootstrap/Image';
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link for navigation
import QuantityControl from './QuantityControl';
import getImageUrl from '../../utils/eshop/getImageUrlUtil';

import { deleteFromCart, updateQuantity } from '../../actions/actions';

import "./ShoppingCartTable.css";

function ShoppingCartTable({ cartItems, deleteFromCart, updateQuantity }) {
  const [reloadTable, setReloadTable] = useState(false);
  const [itemRemoved, setItemRemoved] = useState(false);

  // Handles Quantity Control component
  const handleQuantityChangeItem = (item, newQuantity) => {
    updateQuantity(item.product.id, newQuantity);
  };

  // Handles removing an item from the cart
  const handleRemoveItem = (itemId) => {
    deleteFromCart(itemId);
    setItemRemoved(true); // Trigger re-render after item removal
  };

  // Trigger table reload after an item is removed
  useEffect(() => {
    if (itemRemoved) {
      setReloadTable((prev) => !prev);
      setItemRemoved(false); // Reset itemRemoved state
    }
  }, [itemRemoved]);

  // Calculate total price
  const totalPrice = cartItems.reduce((total, item) => {
    return total + item.quantity * item.product.price;
  }, 0);

  // Helper function to format selected variations
  const formatSelectedVariations = (variations) => {
    if (!variations || variations.length === 0) return null;

    // Extract the `name` property from each variation object
    return variations.map((variation) => variation.name).join(', ');
  };

  // Log the cartItems from the Redux store for debugging
  useEffect(() => {
    console.log("Cart Items from Redux Store:", cartItems);
  }, [cartItems]);

  return (
    <Table striped bordered hover key={reloadTable}>
      <thead>
        <tr>
          <th className="wider-column">Product</th>
          <th>Unit Price</th> {/* New Column for Single Item Price */}
          <th>Quantity</th>
          <th>Total Price</th> {/* This was previously "Price" */}
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {cartItems.map((item, index) => (
          <tr key={index}>
            <td className="wider-column">
              <Image
                className="cart-item-thumbnail"
                thumbnail
                src={getImageUrl(item.product.images[0])}
              />
              <Link to={`/eshop/item/${item.product.slug}`} className="cart-item-link">
                {item.product.name}
              </Link>
              {/* Display selected variations */}
              {item.variations && (
                <div className="selected-variations">
                  <small>{formatSelectedVariations(item.variations)}</small>
                </div>
              )}
            </td>
            <td className="table-unit-price">€{Number(item.product.price).toFixed(2)}</td>
            <td className="table-quantity">
              <QuantityControl
                onQuantityChange={(newQuantity) => handleQuantityChangeItem(item, newQuantity)}
                defaultQuantity={item.quantity}
              />
            </td>
            <td className="table-total-price">€{(item.quantity * Number(item.product.price)).toFixed(2)}</td>
            <td className="table-actions">
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleRemoveItem(item.product.id)}
              >
                Remove
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="4" className="text-right">
            <strong>Total Amount:</strong>
          </td>
          <td>€{totalPrice.toFixed(2)}</td>
        </tr>
      </tfoot>
    </Table>
  );
}

const mapStateToProps = (state) => ({
  cartItems: state.items.cartItems,
});

const mapDispatchToProps = {
  deleteFromCart,
  updateQuantity,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartTable);
