import React, { useState } from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux"; // Import connect
import { addToCart } from "../../actions/actions"; // Import addToCart action
import { v4 as uuidv4 } from "uuid"; // Import UUID library
import AddToCartModal from "./AddToCartModal";
import { getStockStatusClass, getStockStatusText } from "../../utils/eshop/stockUtils";
import getImageUrl from "../../utils/eshop/getImageUrlUtil";

import "./ItemCard.css";

function ItemCard({ item, addToCart }) {
  const [showAlert, setShowAlert] = useState(false);

  const handleAddToCart = (e) => {
    e.preventDefault(); // Prevent default button behavior

    // Generate UUID
    const orderUUID = uuidv4();

    // Dispatch addToCart action with item, quantity, and UUID
    addToCart(item, 1, orderUUID);

    setShowAlert(true); // Show alert when item is added to cart
  };

  // Set the first image as the source
  const imageSource = getImageUrl(item.images[0]);
  // const imageSource = item.images[0];

  // Determine if item is "currently unavailable"
  const isCurrentlyUnavailable = item.stock_status === "currently_unavailable";
  const stockText = getStockStatusText(item.stock_status);
  const stockClass = getStockStatusClass(item.stock_status);
  const buttonText = isCurrentlyUnavailable ? "Currently Unavailable" : "Add to Cart";

  // Conditionally apply 'out-of-stock-card' class only if currently unavailable
  const cardClass = isCurrentlyUnavailable ? "out-of-stock-card" : "";


  return (
    <>
      <Container>
        <Col>
          {/* Render the alert component */}
          <AddToCartModal showAlert={showAlert} setShowAlert={setShowAlert} />
        </Col>
      </Container>
      <Link to={`/eshop/item/${item.slug}`} style={{ textDecoration: "none" }}>
        <Card className={cardClass}>
          <div className="image-container">
            <img src={imageSource} alt={item.name} className="card-img" />
          </div>
          <Card.Body>
            <Card.Title className="card-name">{item.name}</Card.Title>
            <Card.Text className="card-desc">{item.description_short}</Card.Text>
            <Row className="card-price-stock-row">
              <Col>
                <Card.Title className="card-price">€{item.price}</Card.Title>
              </Col>
              <Col>
                <Card.Title className={`card-stock ${stockClass}`}>{stockText}</Card.Title>
              </Col>
            </Row>

            <Button 
              className="cart-button" 
              size="lg" 
              onClick={handleAddToCart} 
              disabled={isCurrentlyUnavailable}
            >
              {buttonText}
            </Button>
          </Card.Body>
        </Card>
      </Link>
    </>
  );
}


// Connect the component to the Redux store
export default connect(null, { addToCart })(ItemCard);
