// Utility function to get the correct image URL based on environment
const getImageUrl = (imagePath) => {
    // Use the environment variable for the image base URL or fallback to localhost in development
    const baseUrl = process.env.REACT_APP_IMAGE_BASE_URL || // Use the value of REACT_APP_IMAGE_BASE_URL from .env file
      (process.env.NODE_ENV === 'production' ? 'https://svkrobotics.com' : 'http://localhost:5000'); // Default for production and dev
  
    return `${baseUrl}${imagePath}`;
  };
  
  export default getImageUrl;
  